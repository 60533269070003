var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "component",
      staticClass: "InlineField__Component",
      class: {
        "has-value": _vm.inputVal !== null,
        invalid: _vm.required && !_vm.inputVal,
        "read-only": _vm.readOnly
      },
      on: {
        mouseover: function($event) {
          return _vm.hover(true)
        },
        mouseleave: function($event) {
          return _vm.hover(false)
        }
      }
    },
    [
      _c("label", [
        _c("div", { staticClass: "InlineField__Component__Placeholder" }, [
          _c(
            "span",
            { staticClass: "InlineField__Component__Placeholder__Text" },
            [_vm._v(_vm._s(_vm.label))]
          ),
          _c(
            "span",
            { staticClass: "InlineField__Component__Placeholder__Icon" },
            [_c("b-icon", { attrs: { icon: "pencil", size: "is-small" } })],
            1
          )
        ]),
        (_vm.type ? _vm.type : "text") === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputVal,
                  expression: "inputVal"
                }
              ],
              style: {
                borderBottom: _vm.borderBottom
                  ? _vm.borderBottom
                  : "1px solid transparent"
              },
              attrs: {
                readonly: _vm.readOnly,
                required: _vm.required,
                maxlength: _vm.max ? _vm.max : "",
                dir: "rtl",
                tabindex: _vm.tabIndex,
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.inputVal)
                  ? _vm._i(_vm.inputVal, null) > -1
                  : _vm.inputVal
              },
              on: {
                focus: function($event) {
                  return _vm.focus(true)
                },
                blur: function($event) {
                  return _vm.focus(false)
                },
                input: function($event) {
                  !_vm.changeOnBlur ? _vm.$emit("onChange") : null
                },
                change: function($event) {
                  var $$a = _vm.inputVal,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputVal = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputVal = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputVal = $$c
                  }
                }
              }
            })
          : (_vm.type ? _vm.type : "text") === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputVal,
                  expression: "inputVal"
                }
              ],
              style: {
                borderBottom: _vm.borderBottom
                  ? _vm.borderBottom
                  : "1px solid transparent"
              },
              attrs: {
                readonly: _vm.readOnly,
                required: _vm.required,
                maxlength: _vm.max ? _vm.max : "",
                dir: "rtl",
                tabindex: _vm.tabIndex,
                type: "radio"
              },
              domProps: { checked: _vm._q(_vm.inputVal, null) },
              on: {
                focus: function($event) {
                  return _vm.focus(true)
                },
                blur: function($event) {
                  return _vm.focus(false)
                },
                input: function($event) {
                  !_vm.changeOnBlur ? _vm.$emit("onChange") : null
                },
                change: function($event) {
                  _vm.inputVal = null
                }
              }
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputVal,
                  expression: "inputVal"
                }
              ],
              style: {
                borderBottom: _vm.borderBottom
                  ? _vm.borderBottom
                  : "1px solid transparent"
              },
              attrs: {
                readonly: _vm.readOnly,
                required: _vm.required,
                maxlength: _vm.max ? _vm.max : "",
                dir: "rtl",
                tabindex: _vm.tabIndex,
                type: _vm.type ? _vm.type : "text"
              },
              domProps: { value: _vm.inputVal },
              on: {
                focus: function($event) {
                  return _vm.focus(true)
                },
                blur: function($event) {
                  return _vm.focus(false)
                },
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputVal = $event.target.value
                  },
                  function($event) {
                    !_vm.changeOnBlur ? _vm.$emit("onChange") : null
                  }
                ]
              }
            })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }